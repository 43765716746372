import {
  useOrganizationFeaturesQuery,
  useOrganizationLicenseQuery,
  useUpdateOrganizationFlagsMutation,
} from "@decentriq/graphql/dist/hooks";
import { OrganizationState } from "@decentriq/graphql/dist/types";
import { Checkbox, Chip, FormControl, FormLabel, Stack } from "@mui/joy";
import { Fragment, memo, useCallback } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import { FeatureVisibilityState } from "models";
import { ConnectorStateSelect } from "./components";

interface OrganizationFeaturesEditorProps {
  organizationId: string;
}

const OrganizationFeaturesEditor: React.FC<OrganizationFeaturesEditorProps> = ({
  organizationId,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin } = useUserRole();

  const {
    data: {
      organization: {
        hasAdvertiserFeatures = false,
        hasAnalyticsFeatures = false,
        hasPublisherFeatures = false,
        hasDataPartnerFeatures = false,
        canViewDataPartners = false,
        canViewMeasurements = false,
        showMigrationPrompt = false,
        allowExcludingSeedAudience = false,
        enableExtendedLookalikeQualityStatistics = false,
        enableAudienceBuilder = false,
        // Data connectors - exports
        s3ExportState = FeatureVisibilityState.Disabled,
        metaExportState = FeatureVisibilityState.Disabled,
        googleDv360ExportState = FeatureVisibilityState.Disabled,
        tradedeskExportState = FeatureVisibilityState.Disabled,
        azureExportState = FeatureVisibilityState.Disabled,
        googleCloudStorageExportState = FeatureVisibilityState.Disabled,
        googleAdManagerExportState = FeatureVisibilityState.Disabled,
        permutiveExportState = FeatureVisibilityState.Disabled,
        sportradarExportState = FeatureVisibilityState.Disabled,
        adformExportState = FeatureVisibilityState.Disabled,
        microsoftDspExportState = FeatureVisibilityState.Disabled,
        splickyExportState = FeatureVisibilityState.Disabled,
        // Data connectors - imports
        localImportState = FeatureVisibilityState.Disabled,
        computeImportState = FeatureVisibilityState.Disabled,
        s3ImportState = FeatureVisibilityState.Disabled,
        snowflakeImportState = FeatureVisibilityState.Disabled,
        salesforceImportState = FeatureVisibilityState.Disabled,
        azureImportState = FeatureVisibilityState.Disabled,
        googleCloudStorageImportState = FeatureVisibilityState.Disabled,
        permutiveImportState = FeatureVisibilityState.Disabled,
      } = {},
    } = {},
  } = useOrganizationFeaturesQuery({
    variables: { organizationId },
  });

  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const organizationLicense = organizationLicenseData?.organization?.state;

  const [updateOrganizationFlagsMutation] =
    useUpdateOrganizationFlagsMutation();

  const updateOrganizationFlags = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      void updateOrganizationFlagsMutation({
        variables: {
          input: {
            [target.name]: target.checked,
          },
          organizationId,
        },
      }).catch((error) => {
        enqueueSnackbar("Available feature settings could not be updated.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      });
    },
    [updateOrganizationFlagsMutation, organizationId, enqueueSnackbar]
  );

  const updateConnectorState = useCallback(
    (connectorName: string, state: FeatureVisibilityState) => {
      void updateOrganizationFlagsMutation({
        variables: {
          input: {
            [connectorName]: state,
          },
          organizationId,
        },
      });
    },
    [updateOrganizationFlagsMutation, organizationId]
  );

  const isEditorDisabled =
    !organizationLicense ||
    [OrganizationState.Archived].includes(organizationLicense);

  const isPublisherCheckboxEnabled =
    organizationLicense === OrganizationState.Passive && isSuperAdmin;

  return (
    <FormControl>
      <FormLabel>Features</FormLabel>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ "& > *": { flex: "0 1 calc(50% - 8px / 2)" } }}
      >
        {isSuperAdmin ? (
          <Fragment>
            <FormControl>
              <Checkbox
                checked={!!hasAdvertiserFeatures}
                disabled={isEditorDisabled}
                label="Advertiser features"
                name="hasAdvertiserFeatures"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!hasAnalyticsFeatures}
                disabled={isEditorDisabled}
                label="Analytics features"
                name="hasAnalyticsFeatures"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!hasPublisherFeatures}
                disabled={isEditorDisabled && !isPublisherCheckboxEnabled}
                label="Publisher features"
                name="hasPublisherFeatures"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!hasDataPartnerFeatures}
                disabled={isEditorDisabled}
                label="Data partner features"
                name="hasDataPartnerFeatures"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!canViewDataPartners}
                disabled={isEditorDisabled}
                label="Can view data partners"
                name="canViewDataPartners"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!canViewMeasurements}
                disabled={isEditorDisabled}
                label="Can view measurements"
                name="canViewMeasurements"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!allowExcludingSeedAudience}
                disabled={isEditorDisabled && !isPublisherCheckboxEnabled}
                label="Allow excluding seed audience"
                name="allowExcludingSeedAudience"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!showMigrationPrompt}
                disabled={isEditorDisabled}
                label="Show migration prompt"
                name="showMigrationPrompt"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={!!enableAudienceBuilder}
                disabled={isEditorDisabled}
                label="Enable audience builder DCR"
                name="enableAudienceBuilder"
                onChange={updateOrganizationFlags}
              />
            </FormControl>
          </Fragment>
        ) : (
          <Fragment>
            {hasAdvertiserFeatures && <Chip>Advertiser</Chip>}
            {hasAnalyticsFeatures && <Chip>Analytics</Chip>}
            {hasPublisherFeatures && <Chip>Publisher</Chip>}
            {hasDataPartnerFeatures && <Chip>Data partner</Chip>}
          </Fragment>
        )}
        <Checkbox
          checked={!!enableExtendedLookalikeQualityStatistics}
          disabled={isEditorDisabled}
          label="Enable extended lookalike quality statistics"
          name="enableExtendedLookalikeQualityStatistics"
          onChange={updateOrganizationFlags}
        />
      </Stack>
      {isSuperAdmin && (
        <Fragment>
          <FormLabel>Data export connectors</FormLabel>
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{ "& > *": { flex: "0 1 calc(50% - 8px / 2)" } }}
          >
            <ConnectorStateSelect
              label="S3 export"
              name="s3ExportState"
              onChange={updateConnectorState}
              state={s3ExportState}
            />
            <ConnectorStateSelect
              label="Meta export"
              name="metaExportState"
              onChange={updateConnectorState}
              state={metaExportState}
            />
            <ConnectorStateSelect
              label="Google DV360 export"
              name="googleDv360ExportState"
              onChange={updateConnectorState}
              state={googleDv360ExportState}
            />
            <ConnectorStateSelect
              label="The Trade Desk export"
              name="tradedeskExportState"
              onChange={updateConnectorState}
              state={tradedeskExportState}
            />
            <ConnectorStateSelect
              label="Azure export"
              name="azureExportState"
              onChange={updateConnectorState}
              state={azureExportState}
            />
            <ConnectorStateSelect
              label="Google Cloud Storage export"
              name="googleCloudStorageExportState"
              onChange={updateConnectorState}
              state={googleCloudStorageExportState}
            />
            <ConnectorStateSelect
              label="Google Ad Manager export"
              name="googleAdManagerExportState"
              onChange={updateConnectorState}
              state={googleAdManagerExportState}
            />
            <ConnectorStateSelect
              label="Permutive export"
              name="permutiveExportState"
              onChange={updateConnectorState}
              state={permutiveExportState}
            />
            <ConnectorStateSelect
              label="Sportradar export"
              name="sportradarExportState"
              onChange={updateConnectorState}
              state={sportradarExportState}
            />
            <ConnectorStateSelect
              label="Adform export"
              name="adformExportState"
              onChange={updateConnectorState}
              state={adformExportState}
            />
            <ConnectorStateSelect
              label="Microsoft DSP export"
              name="microsoftDspExportState"
              onChange={updateConnectorState}
              state={microsoftDspExportState}
            />
            <ConnectorStateSelect
              label="Splicky export"
              name="splickyExportState"
              onChange={updateConnectorState}
              state={splickyExportState}
            />
          </Stack>
          <FormLabel>Data import connectors</FormLabel>
          <Stack
            direction="row"
            flexWrap="wrap"
            sx={{ "& > *": { flex: "0 1 calc(50% - 8px / 2)" } }}
          >
            <ConnectorStateSelect
              label="Local import"
              name="localImportState"
              onChange={updateConnectorState}
              state={localImportState}
            />
            <ConnectorStateSelect
              label="Compute import"
              name="computeImportState"
              onChange={updateConnectorState}
              state={computeImportState}
            />
            <ConnectorStateSelect
              label="S3 import"
              name="s3ImportState"
              onChange={updateConnectorState}
              state={s3ImportState}
            />
            <ConnectorStateSelect
              label="Snowflake import"
              name="snowflakeImportState"
              onChange={updateConnectorState}
              state={snowflakeImportState}
            />
            <ConnectorStateSelect
              label="Salesforce import"
              name="salesforceImportState"
              onChange={updateConnectorState}
              state={salesforceImportState}
            />
            <ConnectorStateSelect
              label="Azure import"
              name="azureImportState"
              onChange={updateConnectorState}
              state={azureImportState}
            />
            <ConnectorStateSelect
              label="Google Cloud Storage import"
              name="googleCloudStorageImportState"
              onChange={updateConnectorState}
              state={googleCloudStorageImportState}
            />
            <ConnectorStateSelect
              label="Permutive import"
              name="permutiveImportState"
              onChange={updateConnectorState}
              state={permutiveImportState}
            />
          </Stack>
        </Fragment>
      )}
    </FormControl>
  );
};

OrganizationFeaturesEditor.displayName = "OrganizationFeaturesEditor";

export default memo(OrganizationFeaturesEditor);
