import { FormControl, Option, Select, Typography } from "@mui/joy";
import { memo } from "react";
import { FeatureVisibilityState } from "models";

interface ConnectorStateSelectProps {
  name: string;
  label: string;
  state: FeatureVisibilityState;
  onChange: (connectorName: string, state: FeatureVisibilityState) => void;
}

const ConnectorStateSelect = memo(
  ({ name, label, state, onChange }: ConnectorStateSelectProps) => {
    return (
      <FormControl>
        <Typography>{label}</Typography>
        <Select
          onChange={(_event, value) => {
            onChange(name, value as FeatureVisibilityState);
          }}
          value={state}
        >
          <Option value={FeatureVisibilityState.Disabled}>Disabled</Option>
          <Option value={FeatureVisibilityState.Hidden}>Hidden</Option>
          <Option value={FeatureVisibilityState.Enabled}>Enabled</Option>
        </Select>
      </FormControl>
    );
  }
);

ConnectorStateSelect.displayName = "ConnectorStateSelect";

export default ConnectorStateSelect;
