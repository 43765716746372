import {
  DqDatasetSyncInfo,
  DqDatasetSyncsList,
  SyncDirectionType,
} from "@decentriq/components";
import {
  useOrganizationFeaturesQuery,
  useOrganizationMicrosoftDspMemberQuery,
} from "@decentriq/graphql/dist/hooks";
import { DataTargetType } from "@decentriq/graphql/dist/types";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ModalDialog,
} from "@mui/joy";
import {
  type Dispatch,
  memo,
  type SetStateAction,
  useMemo,
  useState,
} from "react";
import { useWizard } from "react-use-wizard";
import { type MICROSOFT_DSP_MEMBER } from "containers/OrganizationDataConnectorConfigurationsEditor/OrganizationDataConnectorConfigurationsEditor";
import { useOrganizationPreferences } from "hooks";

interface SelectSyncStepProps {
  onClose?: () => void;
  onSelect: [Dispatch<SetStateAction<DataTargetType | null>>];
}

const SelectSyncStep: React.FC<SelectSyncStepProps> = memo(
  ({ onClose, onSelect: [onSelectSyncType] }) => {
    const { handleStep, nextStep } = useWizard();
    const [sync, setSync] = useState<[DataTargetType | null, null]>([
      null,
      null,
    ]);

    const [syncType] = sync || [];
    handleStep(() => onSelectSyncType(syncType));
    const { organizationId } = useOrganizationPreferences();
    const { data } = useOrganizationFeaturesQuery({
      variables: {
        organizationId,
      },
    });
    const organizationFeatures = data?.organization;

    const { data: organizationMicrosoftDspMemberQueryData } =
      useOrganizationMicrosoftDspMemberQuery({
        skip: !organizationId,
        variables: { organizationId },
      });
    const microsoftDspMember = organizationMicrosoftDspMemberQueryData
      ?.organization?.microsoftDspMember as MICROSOFT_DSP_MEMBER;
    const isMicrosoftDspDisabled =
      !microsoftDspMember || microsoftDspMember === "None";
    const disabledSyncTypes: Record<DataTargetType, boolean> = useMemo(
      () => ({
        [DataTargetType.Adform]:
          organizationFeatures?.adformExportState === "DISABLED",
        [DataTargetType.Azure]:
          organizationFeatures?.azureExportState === "DISABLED",
        [DataTargetType.GoogleAdManager]:
          organizationFeatures?.googleAdManagerExportState === "DISABLED",
        [DataTargetType.GoogleCloudStorage]:
          organizationFeatures?.googleCloudStorageExportState === "DISABLED",
        [DataTargetType.GoogleDv_360]:
          organizationFeatures?.googleDv360ExportState === "DISABLED",
        [DataTargetType.Meta]:
          organizationFeatures?.metaExportState === "DISABLED",
        // TODO follow-up with product about microsoft DSP
        [DataTargetType.MicrosoftDsp]:
          organizationFeatures?.microsoftDspExportState === "DISABLED" ||
          isMicrosoftDspDisabled,
        [DataTargetType.Permutive]:
          organizationFeatures?.permutiveExportState === "DISABLED",
        [DataTargetType.S3]: organizationFeatures?.s3ExportState === "DISABLED",
        [DataTargetType.Splicky]:
          organizationFeatures?.splickyExportState === "DISABLED",
        [DataTargetType.Sportradar]:
          organizationFeatures?.sportradarExportState === "DISABLED",
        [DataTargetType.TradeDesk]:
          organizationFeatures?.tradedeskExportState === "DISABLED",
      }),
      [isMicrosoftDspDisabled, organizationFeatures]
    );

    const hiddenSyncTypes: Record<DataTargetType, boolean> = useMemo(
      () => ({
        [DataTargetType.Adform]:
          organizationFeatures?.adformExportState === "HIDDEN",
        [DataTargetType.Azure]:
          organizationFeatures?.azureExportState === "HIDDEN",
        [DataTargetType.GoogleAdManager]:
          organizationFeatures?.googleAdManagerExportState === "HIDDEN",
        [DataTargetType.GoogleCloudStorage]:
          organizationFeatures?.googleCloudStorageExportState === "HIDDEN",
        [DataTargetType.GoogleDv_360]:
          organizationFeatures?.googleDv360ExportState === "HIDDEN",
        [DataTargetType.Meta]:
          organizationFeatures?.metaExportState === "HIDDEN",
        [DataTargetType.MicrosoftDsp]:
          organizationFeatures?.microsoftDspExportState === "HIDDEN",
        [DataTargetType.Permutive]:
          organizationFeatures?.permutiveExportState === "HIDDEN",
        [DataTargetType.S3]: organizationFeatures?.s3ExportState === "HIDDEN",
        [DataTargetType.Splicky]:
          organizationFeatures?.splickyExportState === "HIDDEN",
        [DataTargetType.Sportradar]:
          organizationFeatures?.sportradarExportState === "HIDDEN",
        [DataTargetType.TradeDesk]:
          organizationFeatures?.tradedeskExportState === "HIDDEN",
      }),
      [organizationFeatures]
    );

    return (
      <ModalDialog>
        <DialogTitle>Export dataset</DialogTitle>
        <Divider />
        <DialogContent>
          <DqDatasetSyncsList
            getDisabledItems={(syncTypes) =>
              syncTypes.filter(
                (syncType) => disabledSyncTypes[syncType as DataTargetType]
              ) as DataTargetType[]
            }
            getFilteredItems={(syncTypes) => {
              return syncTypes.filter(
                (syncType) => !hiddenSyncTypes[syncType as DataTargetType]
              ) as DataTargetType[];
            }}
            onChange={(value) => {
              setSync(value as [DataTargetType, null]);
            }}
            syncDirection={SyncDirectionType.EXPORT}
            value={sync}
          />
        </DialogContent>
        <Divider />
        {syncType ? (
          <DqDatasetSyncInfo
            syncDirection={SyncDirectionType.EXPORT}
            syncType={syncType}
          />
        ) : null}
        <Divider />
        <DialogActions>
          <Button onClick={() => onClose?.()}>Cancel</Button>
          <Button
            color="primary"
            disabled={!syncType}
            onClick={nextStep}
            variant="solid"
          >
            Continue
          </Button>
        </DialogActions>
      </ModalDialog>
    );
  }
);

export default SelectSyncStep;
