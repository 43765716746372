import {
  DqDatasetSyncInfo,
  DqDatasetSyncsList,
  type LocalDataSourceType,
  SyncDirectionType,
} from "@decentriq/components";
import { useOrganizationFeaturesQuery } from "@decentriq/graphql/dist/hooks";
import { DataSourceType } from "@decentriq/graphql/dist/types";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ModalDialog,
} from "@mui/joy";
import {
  type Dispatch,
  memo,
  type SetStateAction,
  useMemo,
  useState,
} from "react";
import { useWizard } from "react-use-wizard";
import { useOrganizationPreferences } from "hooks";

interface SelectSyncStepProps {
  onClose?: () => void;
  onSelect: Dispatch<
    SetStateAction<[DataSourceType | null, LocalDataSourceType | null]>
  >;
}

const SelectSyncStep: React.FC<SelectSyncStepProps> = memo(
  ({ onClose, onSelect }) => {
    const { handleStep, nextStep } = useWizard();
    const [sync, setSync] = useState<
      [DataSourceType | null, LocalDataSourceType | null]
    >([null, null]);
    const [syncType] = sync || [];
    handleStep(() => {
      onSelect(sync);
    });

    const { organizationId } = useOrganizationPreferences();
    const { data } = useOrganizationFeaturesQuery({
      variables: {
        organizationId,
      },
    });
    const organizationFeatures = data?.organization;

    const disabledSyncTypes: Record<DataSourceType, boolean> = useMemo(
      () => ({
        [DataSourceType.Azure]:
          organizationFeatures?.azureImportState === "DISABLED",
        [DataSourceType.Compute]:
          organizationFeatures?.computeImportState === "DISABLED",
        [DataSourceType.GoogleCloudStorage]:
          organizationFeatures?.googleCloudStorageImportState === "DISABLED",
        [DataSourceType.Local]:
          organizationFeatures?.localImportState === "DISABLED",
        [DataSourceType.Permutive]:
          organizationFeatures?.permutiveImportState === "DISABLED",
        [DataSourceType.S3]: organizationFeatures?.s3ImportState === "DISABLED",
        [DataSourceType.Salesforce]:
          organizationFeatures?.salesforceImportState === "DISABLED",
        [DataSourceType.Snowflake]:
          organizationFeatures?.snowflakeImportState === "DISABLED",
      }),
      [organizationFeatures]
    );

    const hiddenSyncTypes: Record<DataSourceType, boolean> = useMemo(
      () => ({
        [DataSourceType.Azure]:
          organizationFeatures?.azureImportState === "HIDDEN",
        [DataSourceType.Compute]:
          organizationFeatures?.computeImportState === "HIDDEN",
        [DataSourceType.GoogleCloudStorage]:
          organizationFeatures?.googleCloudStorageImportState === "HIDDEN",
        [DataSourceType.Local]:
          organizationFeatures?.localImportState === "HIDDEN",
        [DataSourceType.Permutive]:
          organizationFeatures?.permutiveImportState === "HIDDEN",
        [DataSourceType.S3]: organizationFeatures?.s3ImportState === "HIDDEN",
        [DataSourceType.Salesforce]:
          organizationFeatures?.salesforceImportState === "HIDDEN",
        [DataSourceType.Snowflake]:
          organizationFeatures?.snowflakeImportState === "HIDDEN",
      }),
      [organizationFeatures]
    );

    return (
      <ModalDialog>
        <DialogTitle>Import dataset</DialogTitle>
        <Divider />
        <DialogContent>
          <DqDatasetSyncsList
            getDisabledItems={(syncTypes) =>
              syncTypes.filter(
                (syncType) => disabledSyncTypes[syncType as DataSourceType]
              ) as DataSourceType[]
            }
            getFilteredItems={(syncTypes) => {
              return syncTypes.filter(
                (syncType) => !hiddenSyncTypes[syncType as DataSourceType]
              ) as DataSourceType[];
            }}
            onChange={(value) => {
              setSync(value as [DataSourceType, LocalDataSourceType]);
            }}
            syncDirection={SyncDirectionType.IMPORT}
            value={sync}
          />
        </DialogContent>
        <Divider />
        {syncType && syncType !== DataSourceType.Local ? (
          <DqDatasetSyncInfo
            syncDirection={SyncDirectionType.IMPORT}
            syncType={syncType}
          />
        ) : null}
        <Divider />
        <DialogActions>
          <Button onClick={() => onClose?.()}>Cancel</Button>
          <Button
            color="primary"
            disabled={!syncType}
            onClick={nextStep}
            variant="solid"
          >
            Continue
          </Button>
        </DialogActions>
      </ModalDialog>
    );
  }
);
SelectSyncStep.displayName = "SelectSyncStep";

export default SelectSyncStep;
