import { DataSourceType, DataTargetType } from "@decentriq/graphql/dist/types";
import { Grid } from "@mui/joy";
import { memo } from "react";
import { DetailsGridLabel, DetailsGridValue } from "components";
import {
  AdFormConnectionConfiguration,
  AzureConnectionConfiguration,
  GoogleAdManagerConnectionConfiguration,
  GoogleCloudStorageConnectionConfiguration,
  GoogleDV360ConnectionConfiguration,
  MetaConnectionConfiguration,
  MicrosoftConnectionConfiguration,
  PermutiveConnectionConfiguration,
  S3ConnectionConfiguration,
  SalesforceConnectionConfiguration,
  SnowflakeConnectionConfiguration,
  SplickyConnectionConfiguration,
} from "./components";

interface ExternalConnectionConfigurationProps {
  connectionType: DataSourceType | DataTargetType;
  connectionId: string;
}

const ExternalConnectionConfiguration: React.FC<
  ExternalConnectionConfigurationProps
> = ({ connectionType, connectionId }) => {
  return (
    <Grid container={true} direction="column" xs={12}>
      {connectionType !== DataSourceType.Compute && (
        <DetailsGridLabel label="Configuration" />
      )}
      <DetailsGridValue
        value={
          <Grid xs={12}>
            <Grid container={true} direction="column" xs={12}>
              {connectionType === DataSourceType.S3 ||
              connectionType === DataTargetType.S3 ? (
                <S3ConnectionConfiguration connectionId={connectionId} />
              ) : null}
              {connectionType === DataSourceType.GoogleCloudStorage ||
              connectionType === DataTargetType.GoogleCloudStorage ? (
                <GoogleCloudStorageConnectionConfiguration
                  connectionId={connectionId}
                />
              ) : null}
              {connectionType === DataTargetType.GoogleAdManager ? (
                <GoogleAdManagerConnectionConfiguration
                  connectionId={connectionId}
                />
              ) : null}
              {connectionType === DataSourceType.Snowflake ? (
                <SnowflakeConnectionConfiguration connectionId={connectionId} />
              ) : null}
              {connectionType === DataTargetType.Meta ? (
                <MetaConnectionConfiguration connectionId={connectionId} />
              ) : null}
              {connectionType === DataTargetType.GoogleDv_360 ? (
                <GoogleDV360ConnectionConfiguration
                  connectionId={connectionId}
                />
              ) : null}
              {connectionType === DataTargetType.Permutive ? (
                <PermutiveConnectionConfiguration connectionId={connectionId} />
              ) : null}
              {connectionType === DataSourceType.Azure ||
              connectionType === DataTargetType.Azure ? (
                <AzureConnectionConfiguration connectionId={connectionId} />
              ) : null}
              {connectionType === DataSourceType.Salesforce ? (
                <SalesforceConnectionConfiguration
                  connectionId={connectionId}
                />
              ) : null}
              {connectionType === DataTargetType.Adform ? (
                <AdFormConnectionConfiguration connectionId={connectionId} />
              ) : null}
              {connectionType === DataTargetType.MicrosoftDsp ? (
                <MicrosoftConnectionConfiguration connectionId={connectionId} />
              ) : null}
              {connectionType === DataTargetType.Splicky ? (
                <SplickyConnectionConfiguration connectionId={connectionId} />
              ) : null}
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

ExternalConnectionConfiguration.displayName = "ExternalConnectionConfiguration";

export default memo(ExternalConnectionConfiguration);
